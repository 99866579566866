<template>
    <div class="header-container">
        <div style="margin-top: auto;margin-bottom: auto;height: 100%"><img style="height: 140%;margin-top: -1%;margin-left: 3vmin" class="logo" src="../assets/images/biat-logo.png"></div>
    </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";
import router from "@/router";
export default {
    name :'LbrxDisplayHeader',
    props: {
    },
    computed: {
        makeColorDarker(){
            return colorShader.methods.newShade(this.primaryColor, -50);
        },
    }
}
</script>

<style scoped>
.header-container{
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    background: #FFFFFF !important;
    display: flex;
    width: 100%;
    height: 7.8%;
}
.header-container .logo {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4vmin;
}
</style>